@import '@angular/material/theming';
@import 'theme.scss';
a,
a:link,
a:visited {
    color: #7b2cb5;
    outline: 0;
}   

a:hover,
a:active {
    color: #004e66;
    outline: 0;
}

.mat-mdc-form-field {
    width: 100%;
}

.mat-mdc-card-header {
    padding: 16px !important;
}

.spacer {
    width: 20px;
}

.error {
    color: red;
    font-weight: bold;
}



// .publiccard {
//     max-width: 700px;
//     margin: 0 auto;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding-top: 50px;
// }
// .publiccard .mat-card {
//     //width: 100%;
//     margin: 0;
// }
// .tab-content {
//     margin-top: 20px;
// }
// .mat-raised-button.gridbutton {
//     line-height: 30px;
//     min-width: 70px;
// }
// .gridbutton span {
//     font-size: 12px;
//     line-height: 30px;
// }
// .sort-btn {
//     position: absolute;
//     display: block;
//     right: 10px;
//     top: 0;
//     overflow: hidden;
//     margin-top: 15px;
// }
// .filter-select {
//     border: 1px solid #ccc;
//     border-radius: 3.01px;
//     display: block;
//     padding: 3px 3px 3px 3px;
// }
// .filter-input {
//     border: 1px solid #ccc;
//     border-radius: 3.01px;
//     display: block;
//     width: 80%;
//     padding: 4px 3px 4px 3px;
// }
// .ngx-datatable.material .datatable-header .datatable-header-cell {
//     color: #000;
// }
// .td-expansion-panel-header.mat-ripple {
//     background-color: #F7F7F7;
// }
// .td-expansion-panel {
//     margin-bottom: 20px;
//     box-shadow: inherit;
// }
// .tab-content .mat-list-item {
//     border-bottom: 1px solid rgba(0, 0, 0, 0.12);
// }
// .gridbutton {
//     float: right;
//     margin-top: 5px;
// }
// .menu-checkbox {
//     font-size: 13px;
// }
// .menu-icon-color {
//     color: #004e66
// }
// .mat-header-cell {
//     color: black !important;
// }
.ngx-datatable .datatable-footer .pageSizeSelect {
    line-height: 50px;
    height: 50px;
    padding: 0 1.2rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
    display: flex;
    justify-content: flex-end;
}

.ngx-datatable .datatable-footer .pageSizeSelect .mat-mdc-select {
    text-align: center;
    width: 50px;
    display: flex;
}

// .progressSpinner {
//     margin: auto;
// }
// .progressSpinnerWrapper {
//     min-height: 200px;
//     margin:0 !important;
//     padding:0 !important;
//     display: -webkit-box !important;
//     display: -ms-flexbox !important;
//     display: flex !important;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     position: absolute !important;
//     height: 100%;
//     width: 100%;
//     opacity: 0.8;
//     z-index: 1000;
//     box-shadow: none !important;
//     border: none !important;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
// }
// .content-wrapper {
//     position: relative;
//     overflow: hidden;
// }
// .topBar {
//     display: flex;
// }
// .front-logo {
//     max-width: 110px;
//     display: block;
//     margin: 0 auto;
//     margin-bottom: 20px;
// }
// .topbar-buttons {
//     text-align: right;
// }
// .search-input {
//     margin-right: 20px;
//     padding: .375rem .75rem;
//     font-size: 1rem;
//     line-height: 1.5;
//     color: #495057;
//     background-color: #fff;
//     background-clip: padding-box;
//     border: 1px solid #ced4da;
//     border-radius: .25rem;
//     transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
//     @media screen and (max-width: 595px) {
//         margin-right: 0;
//         margin-bottom: 10px;
//     }
// }
// .grid-button {}
// .grid-button:hover {
//     cursor: pointer;
// }
// .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell.action-column,
// .datatable-body-cell.action-column {
//     padding-top: 7px;
// }
// .datatable-scroll { width: 100% !important; }
// .ngx-datatable .datatable-body-cell-label {
//     white-space: normal;
// }
// .datatable-body-cell.action-column .datatable-body-cell-label {
//     text-align: right;
// }
// .not-last-button.mat-raised-button {
//     margin-left: 18px;
// }
// .topBar-title {
//     font-size: 20px;
//     padding-top: 4px;
// }
mat-form-field {
    margin-right: 20px;
}

.row-container {
    margin-bottom: 20px;
}

// .dialog-headline {
//     font-weight: 300;
//     margin-top: -1em;
//     margin-left: -1em;
//     margin-right: -1em;
//     margin-bottom: 0;
//     padding: 0.7em;
//     text-align: left;
//     font-size: 1.5em;
//     background-color: mat-color($primary);
//     color: white;
// }
// .material-icons {
//     vertical-align: middle;
// }
// .matCardDialog {
//     display: initial;
// }
// .mat-dialog-content {
//     padding-top: 24px !important;
//     padding-bottom: 24px;
//     .mat-dialog-actions {
//         float: right;
//     }
//     .dialog-action-button-yes {
//         margin-right: 15px;
//     }
//     .material.ngx-datatable .datatable-footer .page-count {
//         -ms-flex: 1 1 40%;
//         flex: 1 1 40%;
//     }
//     .material.ngx-datatable {
//         box-shadow: none;
//         .datatable-footer .pageSizeSelect {
//             -ms-flex: 1 1 40%;
//             flex: 1 1 40%;
//             padding: unset;
//         }
//         .datatable-footer .pageSizeSelect .mat-select {
//             text-align: center;
//             width: 30%;
//         }
//     }
// }
.no-box-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

// .filterable-column-wrapper {
//     display: flex;
//     flex-direction: row;
// }
// .filterable-column-wrapper input {
//     width: 100%;
//     height: 20px;
//     border: 1px solid #ced4da;
//     border-radius: .25rem;
//     font-size: 12px;
//     margin-right: 0;
// }
// .filterable-column-wrapper label {
//     height:20px;
//     cursor:pointer;
//     padding-right: 20px;
//     width: 100%;
// }
// .text-uppercase {
//     text-transform: uppercase;
// }
// .action-column-header {
//     width: auto !important;
//     white-space: nowrap;
// }
// .only-scroll-horz .datatable-body {
//     overflow-y: hidden;
//     min-height: 5px;
// }
// .datatable-body {
//     max-height: calc(100vh - 300px) !important;
//     overflow: auto overlay !important;
// }
// .disabled-toggle-topbar {
//     margin-right: 16px;
//     align-self: center;
// }
.ngx-datatable.flex-responsive {
    .datatable-body-cell {
        display: flex;
        align-items: center;
        &.action-column {
            justify-content: flex-end;
            padding: 0 !important;
            padding-right: 30px !important;
        }
    }
}

// .required-input {
//     color:red;
// }
// .snackbar-newline {
//     white-space: pre-line !important;
// }
// .chart-wrapper {
//     justify-content: center !important;
// }
// .toolbar-content {
//     display: flex;
//     flex: 1;
//     align-items: center;
// }
// @media screen and (max-width: 1023px) {
//     .filterable-column-wrapper  {
//         flex-direction: column;
//         label {
//             width: 100%;
//             max-width: 100%;
//         }
//         .search-input {
//             margin: 0;
//             max-width: 100%;
//         }
//     }
//     .topBar {
//         .topBar-title {
//             margin-bottom: 5px;
//         }
//         button, a {
//             margin-right: 0 !important;
//             margin-top: 10px;
//         }
//     }
// }
// .cal-week-view .cal-time-events .cal-event {
//     width: auto;
// }
// .angular-editor-button, .angular-editor-toolbar-set {
//     border-radius: 2px !important;
// }
// .angular-editor-toolbar {
//     background-color: #eeeeee !important;
// }
// .angular-editor-button[title="Insert Image"] {
//    display: none;
// }
.w-100 {
    width: 100%;
}

// .send-messages-container {
//     align-items: center;
// }
// .send-messages-container h3 {
//     margin: 0;
// }
// .send-messages-disabled {
//     opacity: .5;
//     pointer-events: none;
//     user-select: none;
// }
.lang-selector {
    font-size: 13px;

        margin-right: 15px; 
        width: 110px;
        .mat-mdc-text-field-wrapper {
            background: transparent;
        }     
        .mat-mdc-form-field-underline {
    
            display: none;
        }

        .mat-mdc-select-value {
        max-width: 100%;
        width: auto;
        padding-right: 8px;
    }
    .mat-mdc-form-field-infix {
        width: auto;
    }
    .mat-mdc-select-value-text span,
    .mat-mdc-select-arrow
    {
        color: #fff;
        font-size: 14px;
    }
    .mat-input-wrapper {
        padding-bottom: 10px;
    }
    .mat-input-underline {
        background-color: transparent !important;
    }
    .mat-mdc-form-field-ripple {
        display: none;
    }
    .mat-mdc-form-field-wrapper {
        padding-bottom: 0.75em;
    }
    .mat-mdc-form-field-underline {
        height: 0 !important;
    }
}

.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    display: block;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.mr-1 {
    margin-right: 4px !important;
}

.ml-1 {
    margin-left: 4px !important;
}

.mt-1 {
    margin-top: 4px !important;
}

.mb-1 {
    margin-bottom: 4px !important;
}

.m-1 {
    margin: 4px !important;
}

// Classes for margin 8px
.mr-2 {
    margin-right: 8px !important;
}

.ml-2 {
    margin-left: 8px !important;
}

.mt-2 {
    margin-top: 8px !important;
}

.mb-2 {
    margin-bottom: 8px !important;
}

.m-2 {
    margin: 8px !important;
}

// Classes for margin 16px
.mr-3 {
    margin-right: 16px !important;
}

.ml-3 {
    margin-left: 16px !important;
}

.mt-3 {
    margin-top: 16px !important;
}

.mb-3 {
    margin-bottom: 16px !important;
}

.m-3 {
    margin: 16px !important;
}

// Classes for margin 32px
.mr-4 {
    margin-right: 32px !important;
}

.ml-4 {
    margin-left: 32px !important;
}

.mt-4 {
    margin-top: 32px !important;
}

.mb-4 {
    margin-bottom: 32px !important;
}

.m-4 {
    margin: 32px !important;
}

// Classes for padding 4px
.pr-1 {
    padding-right: 4px !important;
}

.pl-1 {
    padding-left: 4px !important;
}

.pt-1 {
    padding-top: 4px !important;
}

.pb-1 {
    padding-bottom: 4px !important;
}

.p-1 {
    padding: 4px !important;
}

// Classes for padding 8px
.pr-2 {
    padding-right: 8px !important;
}

.pl-2 {
    padding-left: 8px !important;
}

.pt-2 {
    padding-top: 8px !important;
}

.pb-2 {
    padding-bottom: 8px !important;
}

.p-2 {
    padding: 8px !important;
}

// Classes for padding 16px
.pr-3 {
    padding-right: 16px !important;
}

.pl-3 {
    padding-left: 16px !important;
}

.pt-3 {
    padding-top: 16px !important;
}

.pb-3 {
    padding-bottom: 16px !important;
}

.p-3 {
    padding: 16px !important;
}

// Classes for padding 32px
.pr-4 {
    padding-right: 32px !important;
}

.pl-4 {
    padding-left: 32px !important;
}

.pt-4 {
    padding-top: 32px !important;
}

.pb-4 {
    padding-bottom: 32px !important;
}

.p-4 {
    padding: 32px !important;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.buy-dialog .mat-mdc-dialog-content, .book-item-dialog .mat-mdc-dialog-content, .book-children-birthday-dialog .mat-mdc-dialog-content{
    height: 100%;
}

.buy-dialog .td-loading-wrapper {
    height: 100%;
}

.td-loading-wrapper {
    height: 100%;
}

.menu-logo {
    width: 65px;
    height: 50px;
    background-color: transparent;
}

.public-logo {
    width: 65px;
    height: 100px;
    background-color: transparent;
    margin-bottom: 20px;
}
html,body{
  background-color: #84baec;
  height: 100%;
  padding: 0;
  margin: 0;
}
.monthly-confirm .mat-dialog-container{
  margin: 20px;
}

.mat-mdc-tab-group {
    font-family: Roboto,Helvetica Neue,sans-serif;
}

.mat-mdc-list-item {
    height: 70px;
} 