@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();
// #0093a7
$mat-fhs-primary: ( 50: #e8eaf6, 100: #c5cae9, 200: #9fa8da, 300: #7986cb, 400: #5c6bc0, 500: #3f51b5, 600: #3949ab, 700: #303f9f, 800: #283593, 900: #1a237e, A100: #8c9eff, A200: #536dfe, A400: #3d5afe, A700: #304ffe, contrast: ( 50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #ffffff, A400: #ffffff, A700: #ffffff, ));
$mat-fhs-accent: ( 50: #fff3e0, 100: #ffe0b2, 200: #ffcc80, 300: #ffb74d, 400: #ffa726, 500: #ff9800, 600: #004e66, 700: #f57c00, 800: #0093a7, 900: #e65100, A100: #ffd180, A200: #ffab40, A400: #ff9100, A700: #ff6d00, contrast: ( 50: white, 100: white, 200: white, 300: white, 400: white, 500: white, 600: white, 700: white, 800: white, 900: white, A100: white, A200: white, A400: white, A700: black, ));
$mat-scf: ( 50: #e6e6e9, 100: #c0c1c8, 200: #9698a3, 300: #6c6e7e, 400: #4d4f63, 500: #2d3047, 600: #282b40, 700: #222437, 800: #1c1e2f, 900: #111320, A100: #637dff, A200: #3052ff, A400: #002afc, A700: #0026e2, contrast: ( 50: $black-87-opacity, 100: $black-87-opacity, 200: $black-87-opacity, 300: $black-87-opacity, 400: white, 500: white, 600: $white-87-opacity, 700: $white-87-opacity, 800: $white-87-opacity, 900: $white-87-opacity, A100: $black-87-opacity, A200: $black-87-opacity, A400: white, A700: $white-87-opacity, ));
$primary: mat.define-palette($mat-fhs-primary, 500);
$accent: mat.define-palette($mat-scf);
$warn: mat.define-palette(mat.$orange-palette, 600);
//$theme: mat.define-light-theme($primary, $accent, $warn);
$theme: mat.define-light-theme((
 color: (
   primary: $primary,
   accent: $accent,
   warn: $warn,
 ),
 typography: mat.define-typography-config(),
 density: 0,
));


@include mat.all-component-themes($theme);


/* ------------------------------------------------------------------------------- */

$foreground: map-get($theme, foreground);
$background: map-get($theme, background);
// Apply theme for this app
// NGX Charts
[ngx-charts-axis-label] text {
    fill: mat.get-color-from-palette($foreground, secondary-text);
}

.tick text {
    fill: mat.get-color-from-palette($foreground, disabled);
}

.gridline-path {
    &.gridline-path-horizontal,
    &.gridline-path-vertical {
        stroke: rgba(black, 0.06);
    }
}

.legend-title-text {
    color: mat.get-color-from-palette($foreground, secondary-text);
}

.background-primary {
    background: mat.get-color-from-palette($primary);
}

.text-contrast-primary {
    color: mat.get-color-from-palette($primary, default-contrast) !important;
}

.background-accent {
    background: mat.get-color-from-palette($accent);
}

.text-contrast-accent {
    color: mat.get-color-from-palette($accent, default-contrast) !important;
}
